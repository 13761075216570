<template>
  <b-container>
    <b-row>
      <b-col class="mt-4">
        <b-card class="mb-2" bg-variant="dark" text-variant="white">
          <h1>Console Sign In</h1>
          <b-alert show variant="primary">
            Console is only for clients that have been personally added.<br />
            Any new accounts will need to be created by the RetroNFTs team.
          </b-alert>
          <b-button variant="outline-info" @click="login">
            <b-img
              src="https://www.gstatic.com/dialogflow-console/common/assets/img/new-google-favicon-128.png"
              fluid
              alt="Google logo"
              class="google-logo"
            ></b-img>
            Sign in with Google
          </b-button>
          <div v-if="profileError" class="mt-3">
            <b-alert show variant="danger">
              Sorry, it looks like there isn't an account for your profile.
            </b-alert>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  getAuth,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
} from "firebase/auth";

export default {
  name: "Login",
  mounted() {
    const auth = getAuth();
    getRedirectResult(auth).catch((error) => {
      console.log("error:", error);
    });
  },
  computed: {
    profileError() {
      return this.$store.getters.profileError;
    },
  },
  methods: {
    login() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithRedirect(auth, provider);
    },
  },
};
</script>

<style scoped>
.google-logo {
  max-height: 30px;
  margin-right: 8px;
}
</style>
